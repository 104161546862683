<template>
  <section>
    <b-col cols="12" class="my-2 d-flex justify-content-between flex-wrap">
      <b-col cols="12" :md="item.custom && item.custom.length > 0 || (classifiers && classifiers.length > 0)?'6':'12'" class="px-xl-50">
        <p
          v-if="translate(item.description) && translate(item.description)!==''"
          class="event-detail-descripction pr-2"
          v-html="translate(item.description)"
        />
        <p v-else>
          {{ $t(`${itemType}.about.no-description`) }}
        </p>
      </b-col>
      <b-col
        v-if="itemType!=='organizations' && (item.custom && item.custom.length > 0 || (classifiers && classifiers.length > 0))"
        cols="12"
        md="6"
        class="pl-2"
      >
        <div>
          <!--Custom fields-->
          <b-row
            v-for="(field, index) in item.custom"
            v-show="item.custom"
            :key="index"
            class="mb-1"
          >
            <b-col v-if="field.value" class="font-weight-bold">
              {{ translate(field.customFieldName) }}
            </b-col>
            <b-col>
              <p class="mb-0">
                {{ field.value }}
              </p>
            </b-col>
          </b-row>
          <!-- Classifiers -->
          <template
            v-if="classifiers && classifiers.length > 0"
            v-key="classifiers"
            class="my-50"
          >
            <div
              v-for="(value, name) in sortedClassifiers"
              :key="value.key"
              class="mb-1"
            >
              <div class="w-100 font-weight-bold">
                {{ name }}:
              </div>
              <span v-for="v in value" :key="v.index">
                <b-badge class="my-50 mr-50" :style="classifierStyle(v)">
                  {{ translate(v.name) }}
                </b-badge>
              </span>
            </div>
          </template>
        </div>
      </b-col>

      <b-col
        v-if="itemType==='organizations' && hasInfo"
        cols="12"
        md="6"
        class="mb-50 card p-2 align-self-start"
      >
        <h4 id="information" class="text-dark mb-2 font-weight-600">
          {{ $t("organizations.information.information-title") }}
        </h4>
        <!-- LINKS (only website) -->
        <div
          v-if="item.links && item.links.length > 0"
        >
          <b-row>
            <b-col
              class="font-weight-bold mb-50"
            >
              Website
            </b-col>
            <b-col
              class="website-url mb-50"
            >
              <div
                v-for="link in item.links"
                :key="link.key"
              >
                <a
                  v-if="link.label === 'Website'"
                  class="mb-0"
                  :href="link.url"
                  target="_blank"
                >
                  {{ toAbsoluteURL(link.url) }}
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- EMAILS -->
        <b-row v-if="item.emails && item.emails.length > 0">
          <b-col lg="6" class="font-weight-bold">
            {{ $t("backoffice.settings.subtitle-emails") }}
          </b-col>
          <b-col lg="6">
            <div v-for="email in item.emails" :key="email.key">
              <small class="text-muted mb-0">
                {{ email.label }}
              </small>
              <p>
                <a target="_blank" :href="`mailto:${email.email}`">{{
                  email.email
                }}</a>
              </p>
            </div>
          </b-col>
        </b-row>
        <!-- PHONES -->
        <b-row v-if="item.phones && item.phones.length > 0" class="mb-50">
          <b-col class="font-weight-bold">
            {{
              $t("backoffice.settings.subtitle-phones")
            }}
          </b-col>
          <b-col>
            <div v-for="(phone, index) in item.phones" :key="index">
              <small class="text-muted mb-0">
                {{ phone.label }}
              </small>
              <p :class="item.phones.length > 1 ? 'mb-25' : ''">
                <a
                  v-if="phone.country && phone.country.phoneCode"
                  :href="`tel:+${phone.country.phoneCode}${getPhoneNumber(phone)}`"
                >
                  {{ phone.country.phoneCode ? '+'+phone.country.phoneCode+' ' : '' }}{{ getPhoneNumber(phone) }}
                </a>
                <a v-else :href="`tel:+${getPhoneNumber(phone)}`">
                  {{ getPhoneNumber(phone) }}
                </a>
              </p>
            </div>
          </b-col>
        </b-row>
        <!-- LINKS (except website) -->
        <b-row
          v-if="item.links && item.links.length > 0"
          class="d-flex ml-0"
        >
          <div v-for="link in item.links" :key="link.key">
            <div v-if="link.label !== 'Website'" class="mt-50">
              <a
                v-b-tooltip.hover.bottom="link.label"
                class="mb-0 mr-1 d-flex flex-column social rounded-circle p-75"
                target="_blank"
                :href="toAbsoluteURL(link.url)"
              >
                <feather-icon
                  :icon="
                    hasSocialIcon(link.label)
                      ? `${link.label}Icon`
                      : 'GlobeIcon'
                  "
                  size="20"
                />
              </a>
            </div>
          </div>
        </b-row>
        <!-- Classifiers -->
        <span v-if="Object.values(sortedClassifiers).length > 0" class="mb-1">
          <div v-for="(value, name) in sortedClassifiers" :key="value.key">
            <div v-if="!currentSpaceIsLatamfm" class="w-100 font-weight-bold">{{ name }}:</div>
            <div v-if="!currentSpaceIsLatamfm">
              <span v-for="(v, index) in value" :key="index">
                <b-badge class="mb-50 mr-50" :style="classifierStyle(v)">
                  {{ translate( v.name ) }}
                </b-badge>
              </span>
            </div>
          </div>
          <!-- LATAMFM: Countries -->
          <b-row v-if="currentSpaceIsLatamfm && countries && countries.length > 0" class="mt-50">
            <b-col class="w-100 font-weight-bold">{{ $t("organizations.information.countries") }}</b-col>
            <b-col>
              <div v-for="(country, index) in countries" :key="index">
                <span>
                  <p class="mb-0">
                    {{ country.name.es }}
                  </p>
                </span>
              </div>
            </b-col>
          </b-row>
          <!-- LATAMFM: Services -->
          <b-row v-if="currentSpaceIsLatamfm" class="mt-50">
            <b-col class="w-100 font-weight-bold">{{
              $t("services.title")
            }}</b-col>
            <b-col>
              <div v-for="(service, index) in services" :key="index">
                <span>
                  <p class="mb-0">
                    {{ service.name.es }}
                  </p>
                </span>
              </div>
            </b-col>
          </b-row>
        </span>
        <!-- Custom fields -->
        <div
          v-for="(field, index) in item.custom"
          :key="index"
        >
          <b-row
            v-if="
              field.value ||
                (typeof field.value !== 'string' && !field.value)
            "
            class="mb-1"
          >
            <b-col class="font-weight-bold">
              {{ getName(field) }}
            </b-col>
            <b-col>
              {{ getValue(field.value) }}
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-col>

    <!-- rooms -->
    <b-col
      v-if="item.room"
      cols="12"
      class="px-lg-2 mt-lg-2 border-top pt-2 mb-2"
    >
      <h4 id="rooms" class="text-dark mb-2 font-weight-600">
        {{ $t("community-others.locations") }}
      </h4>
      <!-- Host -->
      <b-row class="mb-2">
        <b-col>
          <b-row class="mb-2">
            <b-col
              v-if="item.room.backgroundURL"
              cols="4"
              md="2"
            >
              <b-img
                class="w-100 round"
                :src="item.room.backgroundURL"
                :alt="item.room.name"
              />
            </b-col>
            <b-col cols="8" md="10">
              <h4 class="mb-2">
                {{ item.room.name }}
              </h4>
              <h6 v-if="item.room.locations" class="mb-0">
                {{ item.room.locations.locality }}
              </h6>
              <p v-if="item.room.locations" class="mb-50">
                {{ item.room.locations.address }}
              </p>
              <b-link
                :to="{ name: 'room', params: { slug: communitySlug, name:item.room.key } }"
                class="small"
              >
                Visitar Sala
              </b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-card
        v-if="item.room.locations"
        no-body
        class="h-100 overflow-hidden"
        style="min-height: 360px"
      >
        <places-map
          :locations="[item.room.locations]"
          :in-container="true"
          style="height: 500px"
        />
      </b-card>
    </b-col>

    <!-- Location -->
    <b-col
      v-else-if="item.locations && item.locations.length > 0"
      cols="12"
      class="px-lg-2 mt-lg-2 border-top pt-2 mb-2"
    >
      <h4 id="locations" class="text-dark mb-2 font-weight-600">
        {{ $t("community-others.locations") }}
      </h4>
      <!-- Locations -->
      <b-row class="mb-2">
        <b-col v-for="(location, index) in item.locations" :key="index">
          <h6>
            {{ location.label }}
          </h6>
          <p>
            {{ location.address }}
          </p>
        </b-col>
      </b-row>
      <!-- Map -->
      <b-card no-body class="h-100 overflow-hidden" style="min-height: 360px">
        <places-map
          :locations="item.locations"
          :in-container="true"
          style="height: 500px"
        />
      </b-card>
    </b-col>
  </section>
</template>

<script>
import PlacesMap from '@core/components/places-map-input/PlacesMap.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { dateValidator } from '@/@core/utils/validations/validators';
import { toAbsoluteURL } from '@core/utils/url-utils';

export default {
  name: 'AboutDetail',
  components: {
    PlacesMap,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSmall: false,
      countries: [],
      services: [],
    };
  },
  computed: {
    communitySlug() {
      return this.$store.getters.currentCollective.slug;
    },
    cFields() {
      return this.$store.getters.customFields;
    },
    currentSpaceIsLatamfm() {
      return (this.$store.getters.currentCollective.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5');  
    },
    hasInfo() {
      return (
        this.item?.emails?.length > 0
        || this.item?.phones?.length > 0
        || this.item?.links?.length > 0
        || Object.values(this.sortedClassifiers).length > 0
      );
    },
    sortedClassifiers() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (
          !newObject[this.translate(item.typeName)]
        ) {
          newObject[this.translate(item.typeName)] = [item];
        } else {
          newObject[this.translate(item.typeName)].push(item);
        }
      });
      return newObject;
    },
    classifiersData() {
      return this.item?.classifiers;
    },
    classifiers() {
      return this.classifiersData?.reduce((acc, current) => {
        const x = acc.findIndex((item) => item.key === current.key);
        if (x === -1) {
          return acc.concat([current]);
        }
        acc[x] = current;
        return acc;
      }, []);
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.currentSpaceIsLatamfm) {
      this.countries = this.classifiers.filter(({ typeName }) => typeName.es === 'País');
      this.services = this.classifiers.filter(({ typeName }) => typeName.es !== 'País');
      // Remove duplicated
      this.countries = this.countries.reduce((acc, current) => {
        const x = acc.findIndex((item) => item.key === current.key);
        if (x === -1) {
          return acc.concat([current]);
        }
        acc[x] = current;
        return acc;
      }, []).sort();
      this.services = this.services.reduce((acc, current) => {
        const x = acc.findIndex((item) => item.key === current.key);
        if (x === -1) {
          return acc.concat([current]);
        }
        acc[x] = current;
        return acc;
      }, []).sort();
    }
  //  await this.fetchCustomFields();
    // await this.fetchClassifiers();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    hasSocialIcon(site) {
      // Social media icons: Instagram, Facebook, Linkedin, Youtube, Twitter. The rest with globe icon
      // Social media names obtained from @copernicsw/community-common/link/
      // TODO: Replace by enum when commos is updated.
      return [
        'Instagram',
        'Facebook',
        'Linkedin',
        'Youtube',
        'Twitter',
      ].includes(site);
    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    getName(field) {
      let name = '';
      if (Array.isArray(this.cFields)) {
        this.cFields.map((item) => {
          if (field.key === item.key) {
            name = item.name;
          }
        });
        return this.translate(name);
      }
      return name;
    },
    getValue(value) {
      if (dateValidator(value)) {
        return value.split('-').reverse().join('-');
      }
      if (value === true) {
        return this.$t('yes');
      }
      if (value === false) {
        return this.$t('no');
      }
      return value;
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        'background-color': classifier.backgroundColor,
      };
    },
    handleResize() {
      this.isSmall = window.innerWidth < 800;
    },
    toAbsoluteURL,
    getPhoneNumber(phone){
      return phone.phone ?? phone.number;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
.map-container > .map {
  height: 100%;
  position: initial !important;
}
.classifier-content {
  width: 85%;
}
.event-detail-descripction::v-deep img {
  max-width: 100% !important;
  height: auto !important;
}
</style>
